import React from 'react'
import ReactDOM from 'react-dom/client'

/* CSS */
import './design/css/app.css'
import './design/css/preloader.css'
import './design/css/play.css'
import './design/css/bet.css'
import './design/css/buttons.css'
import './design/css/rules.css'
import './design/css/notification.css'
import './design/css/inactiontiming.css'
import './design/css/player-card.css'
import './design/css/player.css'
import './design/css/additional.css'
import './design/css/dealer.css'
import './design/css/floatingmessages.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(token.length > 64 ? <App /> : <div className="page-404"><span>404</span> Page not found</div>)

reportWebVitals()