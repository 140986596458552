import React, { Component } from "react"

/* Constants */
import { utils, sound, env } from '../constants'

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Components */
import { Picture, Rates } from "@alicorn/poker-ui"


/* Widget Bet */
class Bet extends Component {


    constructor() {
        super()

        this.state = {
            history: [],
        }
    }

    getChipsList = () => {
        const { chips } = this.props
        return utils.getChips(chips)
    }

    add = () => {

        const { history } = this.state
        const { setAnte, ante, balance, toggleBalanceError, toggleMaxBetError, active, max } = this.props

        const chipsList = this.getChipsList()

        let list = history
        const index = chipsList.findIndex(c => c.key === active)

        if (index > -1) {

            const current = chipsList[index]

            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < parseInt(ante + current.value) * 4) {
                toggleBalanceError()
                return
            }

            if (parseInt(ante + current.value) > parseInt(max)) {
                toggleMaxBetError()
                return
            }

            const uid = uuidv4()
            list.push({ ...current, uid })
            this.setState({ history: list })

            setAnte(ante + current.value)

        }
    }


    /* Clear action */
    clear = () => {
        const { setAnte } = this.props
        this.setState({ history: [] })
        setAnte(0)
        sound.play()
    }


    /* Cancel action */
    cancel = () => {

        const { history } = this.state
        const { setAnte, ante } = this.props

        let list = history

        if (ante > 0 && history.length > 0) {
            const last = list.pop()

            this.setState({ history: list })
            setAnte(ante - last.value)

            // sound.play()
        }
    }


    /* Double action */
    double = () => {

        const { history } = this.state
        const { setAnte, ante, balance, toggleBalanceError, toggleMaxBetError, max } = this.props

        let list = history

        const chipsList = this.getChipsList()

        if (ante > 0) {

            let image = {}
            chipsList.forEach(chip => {
                if (parseInt(ante) >= chip.value) {
                    image = chip.image3d
                }
            })

            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < parseInt(ante * 2) * 4) {
                toggleBalanceError()
                return
            }

            if (parseInt(ante * 2) > parseInt(max)) {
                toggleMaxBetError()
                return
            }

            const uid = uuidv4()
            list.push({ key: 7, value: ante, image, image3d: image, uid })
            this.setState({ history: list })
            setAnte(ante * 2)

        }
    }


    /* Refresh action */
    refresh = () => {

        const { history } = this.state
        const { setAnte, ante, last, balance, toggleBalanceError, toggleMaxBetError, max } = this.props

        const chipsList = this.getChipsList()

        let list = history

        if (last > 0 && ante === 0) {

            let image = {}
            chipsList.forEach(chip => {
                if (parseInt(last) >= chip.value) {
                    image = chip.image3d
                }
            })

            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < parseInt(last) * 4) {
                toggleBalanceError()
                return
            }


            if (parseInt(last) > parseInt(max)) {
                toggleMaxBetError()
                return
            }

            const uid = uuidv4()
            list.push({ key: 8, value: last, image, image3d: null, uid })
            this.setState({ history: list })
            setAnte(last)
        }

    }


    /* SET DEFAULT CHIP */
    setActiveChip = item => {

        const { active, setActiveChip } = this.props

        if (item.key !== active) {
            setActiveChip(item.key)
            sound.play('selectchip') // PLAY SOUND
        }
        else {
            this.add()
        }

    }


    /* Draw Chips */
    _chips = () => {

        const { active } = this.props

        const list = this.getChipsList()

        return (
            <div className="chips">

                {list.map((item, index) =>
                    <div onClick={() => this.setActiveChip(item)} key={`${index}`} className={item.key === active ? `chip active-chip` : `chip`}>
                        <Picture src={`${item.image}`} alt={`CHIP ${item.value}`} />
                        <div className={`chip-value size_${utils.fontSize(item.value)}`}>{utils.counter(item.value)}</div>
                    </div>
                )}

            </div>

        )
    }


    render = () => {

        const { history } = this.state
        const { ante, last, currency, boxStyle, anteAnimatedStyle, active, chips } = this.props

        const disabled = !ante

        const cancel_disabled = history.length === 0

        return (
            <div className="bet">

                <div className="bet-panel">

                    <div className="bet-buttons">

                        {/* Clear Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.clear()}>
                            <Picture src={`${env.mediapoint}/images/bet/delete.png`} alt="Delete" />
                            <span>Очистить</span>
                        </div>

                        {/* Cancel Button */}
                        <div className={`bet-button ${cancel_disabled && 'disabled'}`} onClick={() => this.cancel()}>
                            <Picture src={`${env.mediapoint}/images/bet/cancel.png`} alt="Cancel" />
                            <span>Отменить</span>
                        </div>

                    </div>

                    {this._chips()}

                    <div className="bet-buttons">

                        {/* Double Up Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.double()}>
                            <div className="bet-title">2x</div>
                            <span>Удвоить</span>
                        </div>

                        {/* Cancel Button */}
                        <div className={`bet-button ${(!last || ante) && 'disabled'}`} onClick={() => this.refresh()}>
                            <Picture src={`${env.mediapoint}/images/bet/refresh.png`} alt="Refresh" />
                            <span>Повторить</span>
                        </div>

                    </div>

                </div>

                <div className="pc-rates-outer">
                    <Rates currency={currency} history={history} fromBet={true} add={() => this.add()} ante={ante} active={active} bet={() => { }} boxStyle={boxStyle} anteAnimatedStyle={anteAnimatedStyle} chips={chips} />
                </div>

            </div>
        )
    }

}

export default Bet