import * as env from './env'

const data = [
    `/images/backImg.jpeg`,
    `${env.mediapoint}/images/back-casino.webp`,
    `${env.mediapoint}/images/chip.png`,
    `${env.mediapoint}/images/close.png`,
    `${env.mediapoint}/images/compress.png`,
    `${env.mediapoint}/images/dealer.png`,
    `${env.mediapoint}/images/error.png`,
    `${env.mediapoint}/images/exchange.png`,
    `${env.mediapoint}/images/expand.png`,
    `${env.mediapoint}/images/high-volume.png`,
    `${env.mediapoint}/images/history.png`,
    `${env.mediapoint}/images/information.png`,
    `${env.mediapoint}/images/logo.png`,
    `${env.mediapoint}/images/no-video.png`,
    `${env.mediapoint}/images/play-button.png`,
    `${env.mediapoint}/images/undo.png`,
    `${env.mediapoint}/images/up-arrow.png`,
    `${env.mediapoint}/images/user.png`,
    `${env.mediapoint}/images/video-white.png`,
    `${env.mediapoint}/images/video.png`,
    `${env.mediapoint}/images/volume-mute.png`,
    `${env.mediapoint}/images/x.png`,
    `${env.mediapoint}/images/bet/cancel.png`,
    `${env.mediapoint}/images/bet/delete.png`,
    `${env.mediapoint}/images/bet/play.png`,
    `${env.mediapoint}/images/bet/refresh.png`,
    `${env.mediapoint}/images/bet/tap.png`,
    `${env.mediapoint}/images/buttons/bet.png`,
    `${env.mediapoint}/images/buttons/insurance.png`,
    `${env.mediapoint}/images/buttons/pass.png`,
    `${env.mediapoint}/images/buttons/sixth2.png`,
    `${env.mediapoint}/images/buttons/swap.png`,
    `${env.mediapoint}/images/info/success.png`,
    `${env.mediapoint}/images/info/warning.png`,
    `${env.mediapoint}/images/time-left.png`,
    `${env.mediapoint}/images/chat.png`,
    `${env.mediapoint}/images/send2.svg`,
    `${env.mediapoint}/images/user-connect.png`,

    `${env.mediapoint}/chips/1.webp`,
    `${env.mediapoint}/chips/1_3d.webp`,

    `${env.mediapoint}/chips/2.webp`,
    `${env.mediapoint}/chips/2_3d.webp`,
    
    `${env.mediapoint}/chips/3.webp`,
    `${env.mediapoint}/chips/3_3d.webp`,

    `${env.mediapoint}/chips/4.webp`,
    `${env.mediapoint}/chips/4_3d.webp`,

    `${env.mediapoint}/chips/5.webp`,
    `${env.mediapoint}/chips/5_3d.webp`,

    `${env.mediapoint}/chips/6.webp`,
    `${env.mediapoint}/chips/6_3d.webp`,

    `${env.mediapoint}/cards/closed.webp`,

    `${env.mediapoint}/cards/club-2.webp`,
    `${env.mediapoint}/cards/club-3.webp`,
    `${env.mediapoint}/cards/club-4.webp`,
    `${env.mediapoint}/cards/club-5.webp`,
    `${env.mediapoint}/cards/club-6.webp`,
    `${env.mediapoint}/cards/club-7.webp`,
    `${env.mediapoint}/cards/club-8.webp`,
    `${env.mediapoint}/cards/club-9.webp`,
    `${env.mediapoint}/cards/club-10.webp`,
    `${env.mediapoint}/cards/club-jack.webp`,
    `${env.mediapoint}/cards/club-queen.webp`,
    `${env.mediapoint}/cards/club-king.webp`,
    `${env.mediapoint}/cards/club-ace.webp`,

    `${env.mediapoint}/cards/diamond-2.webp`,
    `${env.mediapoint}/cards/diamond-3.webp`,
    `${env.mediapoint}/cards/diamond-4.webp`,
    `${env.mediapoint}/cards/diamond-5.webp`,
    `${env.mediapoint}/cards/diamond-6.webp`,
    `${env.mediapoint}/cards/diamond-7.webp`,
    `${env.mediapoint}/cards/diamond-8.webp`,
    `${env.mediapoint}/cards/diamond-9.webp`,
    `${env.mediapoint}/cards/diamond-10.webp`,
    `${env.mediapoint}/cards/diamond-jack.webp`,
    `${env.mediapoint}/cards/diamond-queen.webp`,
    `${env.mediapoint}/cards/diamond-king.webp`,
    `${env.mediapoint}/cards/diamond-ace.webp`,

    `${env.mediapoint}/cards/heart-2.webp`,
    `${env.mediapoint}/cards/heart-3.webp`,
    `${env.mediapoint}/cards/heart-4.webp`,
    `${env.mediapoint}/cards/heart-5.webp`,
    `${env.mediapoint}/cards/heart-6.webp`,
    `${env.mediapoint}/cards/heart-7.webp`,
    `${env.mediapoint}/cards/heart-8.webp`,
    `${env.mediapoint}/cards/heart-9.webp`,
    `${env.mediapoint}/cards/heart-10.webp`,
    `${env.mediapoint}/cards/heart-jack.webp`,
    `${env.mediapoint}/cards/heart-queen.webp`,
    `${env.mediapoint}/cards/heart-king.webp`,
    `${env.mediapoint}/cards/heart-ace.webp`,

    `${env.mediapoint}/cards/spade-2.webp`,
    `${env.mediapoint}/cards/spade-3.webp`,
    `${env.mediapoint}/cards/spade-4.webp`,
    `${env.mediapoint}/cards/spade-5.webp`,
    `${env.mediapoint}/cards/spade-6.webp`,
    `${env.mediapoint}/cards/spade-7.webp`,
    `${env.mediapoint}/cards/spade-8.webp`,
    `${env.mediapoint}/cards/spade-9.webp`,
    `${env.mediapoint}/cards/spade-10.webp`,
    `${env.mediapoint}/cards/spade-jack.webp`,
    `${env.mediapoint}/cards/spade-queen.webp`,
    `${env.mediapoint}/cards/spade-king.webp`,
    `${env.mediapoint}/cards/spade-ace.webp`,

]

export { data }