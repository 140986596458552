import React, { Component } from "react"

/* Fields */
const DEFAULT_TIME = 300

/* Component InactionTiming */
class InactionTiming extends Component {

    constructor() {
        super()

        this.state = {
            counter: DEFAULT_TIME
        }

        this._timer = 0
    }


    componentDidMount = () => {
        this.start()
    }

    componentWillUnmount = () => {
        clearInterval(this._timer)
    }

    /* Start Timing */
    start = () => {
        if (this._timer === 0 && this.state.counter > 0) {
            this._timer = setInterval(this.countDown, 1000)
        }
    }

    /* Count Down action */
    countDown = () => {

        const { counter } = this.state
        const { isPaused, setInactionState } = this.props

        const second = counter - 1

        if ((!isPaused)) {

            this.setState({ counter: second })

            if (second === 0) {
                clearInterval(this._timer)
                setInactionState()
            }

        }
    }

    /* Reset Counter */
    reset = () => {
        this.setState({ counter: DEFAULT_TIME })
    }

    render = () => <div />
}

export default InactionTiming