import React, { Component, createRef } from 'react'

/* Socket IO */
import { io } from "socket.io-client"

/* JWT */
import { decodeToken } from 'react-jwt'

/* Constants */
import { images, env, sound, times } from './constants'
import { LOADING, ERROR, CHOICE, GAME, WAITING, DEALING } from './constants/status'

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Alicorn Poker UI */
import { Error, Background, GameControl, Balance, Play, Pass, Fifth, Swap, Insurance, Internet, GameInfo, Status, Timer, FloatingMessages, Notification, Rates, MobileRate } from '@alicorn/poker-ui'

/* Widgets */
import { Preloader, PlayerCards, DealerCards } from './widgets'

/* Rules */
import { list } from './constants/rules'

/* REST API */
import { history } from './api/History'

/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage } from '@alicorn/iframe-message-sender'

/* Components */
import { InactionTiming, GamePayout } from './components'

/* Widgets */
import { Bet } from './widgets'

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

/* Intenet speed check url */
const imgUrl = "https://testing-cb.makaobet.com/images/dealerinfo/close.png"

/* FRAME SETTINGS */
setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })


/* Entry Point */
class App extends Component {


    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {

            /* General */
            cached: false,
            status: LOADING,
            balance: null,
            total: 0,
            currency: (token && data && data.currency) ? data.currency : 'USD',
            identifier: (token && data && data.identifier) ? data.identifier : '1',
            lastWin: 0,
            activeChip: 3,
            ante: 0,
            last: 0,
            total: 0,
            result: null,

            /* Player */
            playerCards: [],
            playerGame: null,

            /* Dealer */
            dealerInfo: null,
            dealerCards: [],
            dealerGame: null,

            /* Additional */
            additionalCards: [],

            /* Game Info */
            max: data && data.max ? data.max : 5000,
            min: data && data.min ? data.min : 50,
            maxPay: data && data.maxPay ? data.maxPay : 100000,

            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME,
                purchaseTime: times.PURCHASE_GAME_STAGE_TIME
            },

            stage: null,
            activeAction: false,
            idOpen: false,
            isBet: false,
            isPass: false,
            isFifth: false,
            used: false,
            exchangeCards: [],
            isPurchase: false,
            purchaseRemovedCard: null,
            showInsurance: false,
            insurance: 0,
            insuranceStatus: null,
            gameInfo: null,
            transactions: [],
            operations: [],
            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            /* Game Session */
            closed: false,
            newDevice: false,
            isDisconnected: false,
            isPaused: false,

            /* Other */
            hls: data && data.hls ? data.hls : null,
            chips: data && data.chips && Array.isArray(data.chips) ? data.chips : [],
            backColor: data && data.backColor ? data.backColor : "",
            gameImg: data && data.gameImg ? `${env.mediapoint}/images/table-images/` + data.gameImg : null,
        }

        this.notifyRef = React.createRef()

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false, isPlayer: true } })

        /* Timing ref */
        this._balanceErrorTiming = null
        this._maxBetErrorTiming = null

        /* Backfround ref */
        this._background = createRef(null)
    }

    componentDidMount = () => {
        this.game()
        this.cache()
        this.loadHistory(1)
    }

    componentWillUnmount = () => {
        this.socket.disconnect()
        this.clear()
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {
                let list = response.data

                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 5) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }

    /* Send Message */
    sendMessage = message => {
        this.socket.emit("messageFromPlayer", message.message)
        if (this._floatingMessages) {
            this._floatingMessages.sendMessage(message)
        }
    }

    game = () => {
        /* On error */
        this.socket.on("connect_error", () => {

            this.clear()

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
            this.clear()
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                this.setState({ status: null })
                clearTimeout(timer)
                this.resetTimer()
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
            this.clear()
        })

        /* On start */
        this.socket.on("status", data => {
            const { status, ante } = this.state

            if (status !== data) {
                if (data === CHOICE && status !== CHOICE) {
                    this.loadHistory(1)
                    this.clear()
                }
                if (data === GAME) {
                    this.setState({ activeAction: null })
                }
                if (data !== CHOICE && !ante) {
                    this.setState({ status: WAITING })
                }
                else {
                    this.setState({ status: data })
                }
            }
        })


        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        this.socket.on("reconnection", data => {
            if (data) {

                this.reconnectionClear()

                if (data.reason && data.reason === "transaction") {
                    this.notify("transaction", 'show')
                }

                const status = data.status
                const ante = data.ante ? data.ante : 0
                const times = data.times
                const stage = data.stage ? data.stage : null

                const playerCards = data.playerCards
                const playerGame = data.playerGame

                const additionalCards = data.additionalCards

                const dealerCards = data.dealerCards
                const dealerGame = data.dealerGame

                const exchangeCards = data.exchangeCards

                const isPaused = data.isPaused

                setTimeout(() => {

                    this.setState({ status })

                    if (isPaused) {
                        this.notify("pause", "show")
                    }

                    if (data.autopass) {
                        this.notify("autopass", "show")
                        setTimeout(() => {
                            this.notify("autopass", "close")
                        }, 5000);
                    }

                    this.notify("pause", 'close')
                    this.notify("transaction", 'close')

                    if (ante) {
                        this.setState({
                            ante,
                            total: data.total,
                            times,
                            isPaused,

                            gameInfo: data.gameData,

                            playerGame,
                            dealerGame,

                            playerCards,
                            dealerCards,
                            additionalCards,
                            exchangeCards,
                            purchaseRemovedCard: data.removedCard,

                            isBet: data.bet,
                            isPass: data.pass,
                            isFifth: data.fifth,
                            isExchange: data.exchange,
                            used: data.used,

                            activeAction: null,

                            insurance: data.insurance,
                            insuranceStatus: data.insuranceStatus,

                            isPurchase: data.isPurchase,
                            result: data.result,

                            transactions: data.transactions

                        })
                    }

                    this.setState({ stage: data.stage, dealerInfo: data.dealerData })

                    setTimeout(() => {

                        if (this._timing && times && stage && parseInt(times[stage]) > 0) {
                            this._timing.start(times[stage], stage)
                        }
                        else {
                            setTimeout(() => {
                                if (this._timing && times && stage && parseInt(times[stage]) > 0) {
                                    this._timing.start(times[stage], stage)
                                }
                            }, 1500)
                        }

                    }, 1000)

                }, 2000)
            }
        })

        /* Get pause command from dealer monitor */
        this.socket.on("pause", () => {
            this.setState({ isPaused: true })
            this.notify('pause', 'show')
        })

        /* Continue Event */
        this.socket.on("continue", () => {
            this.setState({ isPaused: false })
            this.notify('pause', 'close')
        })

        /* Get a game status after inaction  */
        this.socket.on("actions", data => {

            const { used, pass, bet, clearExchangeCards } = data

            const { total, ante } = this.state

            this.setState({ used: used, isPass: pass ? true : false, isBet: bet ? true : false, showInsurance: false })

            if (!used && this._playerCards) {
                this._playerCards.clearCards()
            }

            if (clearExchangeCards && this._playerCards) {
                this.setState({ exchangeCards: [] })
                this._playerCards.clearCards()
            }

            if (bet) {
                this.setState({ total: total + ante * 2 })
            }

            if (pass) {
                this.setState({ status: DEALING })
                this.notify('autopass', 'show')
                sound.play('autopass')
                setTimeout(() => { this.notify('autopass', 'close') }, 5000)
            }
        })

        /* Get a dealer Info */
        this.socket.on("dealerData", data => {
            this.setState({ dealerInfo: data })
        })

        /* Get a dealer card */
        this.socket.on("dealer", data => {
            const { dealerCards } = this.state
            let cards = dealerCards
            cards.unshift(data)
            this.setState({ dealerCards: cards })
        })

        this.socket.on("dealerCards", data => {
            this.setState({ dealerCards: data })
        })

        this.socket.on("dealerGame", data => {
            this.setState({ dealerGame: data })
        })

        /* Get a Player card */
        this.socket.on("player", data => {
            const { playerCards } = this.state
            let cards = playerCards
            cards.unshift(data)
            this.setState({ playerCards: cards })
            sound.play('clickuibut', 0.2)
        })

        this.socket.on("playerGame", data => {
            this.setState({ playerGame: data })
        })


        /* Additional Cards */
        this.socket.on("additional", data => {
            const { additionalCards } = this.state
            let cards = additionalCards
            cards.unshift(data)
            this.setState({ additionalCards: cards })
            sound.play('clickuibut', 0.2)
        })

        /* Additional Cards Array */
        this.socket.on("additionalCards", data => {
            this.setState({ additionalCards: data })
            sound.play('clickuibut', 0.2)
        })

        this.socket.on("additionalCards", data => {
            this.setState({ additionalCards: data })
        })

        /* Get Insurance Status */
        this.socket.on("insuranceStatus", data => {
            this.setState({ insuranceStatus: data })
            if (data === 'win') {
                sound.play('winsmall')
            } else {
                sound.play('selectchip')
            }
        })

        /* Purchase Card */
        this.socket.on("purchase", () => {
            this.setState({ isPurchase: true })
        })

        /* Purchase Card */
        this.socket.on("purchaseCard", data => {
            const { dealerCards } = this.state
            let cards = [...dealerCards, data]
            this.setState({ dealerCards: cards, purchase: true })
            sound.play('clickuibut', 0.2)
        })

        /* Purchase Removed Card */
        this.socket.on("purchaseRemovedCard", data => {
            this.setState({ purchaseRemovedCard: data })
        })

        /* Timer Stage */
        this.socket.on("stage", data => {
            const { type, times } = data

            this.setState({ stage: type })
            if (this._timing && times && parseInt(times[type]) > 0 && type) {
                this._timing.start(times[type], type)
            }

        })

        /* Result */
        this.socket.on("result", result => {
            this.setState({ result })
            if (result && result.result === "win") {
                sound.play('win', 0.5)
                this.setState({ lastWin: result.sum })
            }
        })

        /* Message */
        this.socket.on("message", data => {
            if (this._floatingMessages) {
                this._floatingMessages.sendMessage(data)
            }
            if (this._background && this._background.current) {
                this._background.current.addMessage(data)
            }
        })

        /* Get Game Info */
        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get refund Ante inform */
        this.socket.on("refundAnte", data => {
            const { total } = this.state
            this.notify("refund", "refund", data)
            this.setState({ total: total / 2 })
        })

        /* Get transactions list */
        this.socket.on("transaction", data => {
            if (data.id) {
                const { transactions, operations } = this.state
                let list = transactions
                let operationsList = operations
                list.push(data)
                operationsList.push(data)
                this.setState({ transactions: list, operations: operationsList })

                setTimeout(() => {
                    let operationsList = this.state.operations
                    operationsList = operationsList.filter(item => item.id !== data.id)
                    this.setState({ operations: operationsList })
                }, 3500)

            }
        })

        this.socket.on("editTransaction", data => {
            const { transactions } = this.state
            let list = transactions
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ transactions: list })
            }
        })


        this.socket.on("forceEnd", () => {
            this.forceEnd()
        })

    }

    /* Show and close notifications */
    notify = (type, action, data = null) => {

        const notifyData = { type, action, data }

        if (this._notify) {
            this.notifyAction(notifyData)
        }
        else {
            setTimeout(() => {
                if (this._notify) {
                    this.notifyAction(notifyData)
                }
            }, 2000)
        }

    }

    /* Do notify actions after notify component check */
    notifyAction = (notifyData) => {

        const { type, action, data = null } = notifyData
        if (action === "show") {
            this._notify.show(type)
        }
        else if (action === 'close') {
            this._notify.close(type)
        }
        else if (action === 'refund') {
            if (data.total) {
                const title = data.type === 'refund' ? 'Возврат средств' : data.type === 'refundAnte' ? "Возврат половины анте" : "Пополнение суммы"
                const uid = uuidv4()
                this._notify.refund({ title, total: data.total, uid })
            }
        }

    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {
        if (parseInt(speed) < 5) {
            this.notify('internet', 'show')
        }
        else {
            this.notify('internet', 'close')
        }
    }

    /* Cache images */
    cache = async () => {

        const promises = images.data.map(uri => {

            return new Promise(function (resolve, reject) {
                const img = new Image()
                img.src = uri
                img.onload = resolve()
                img.onerror = reject()
            })

        })

        await Promise.all(promises)

        this.setState({ cached: true })
    }

    /* Reser top timing */
    resetTimer = () => {
        if (this._inaction) {
            this._inaction.reset()
        }
    }

    /* Disconnect user when inaction timer equal to 0 */
    setInactionState = () => {
        this.setState({ closed: true })
        this.socket.disconnect()
        this.clear()
    }

    clear = () => {
        this.setState({
            ante: 0,
            playerCards: [],
            playerGame: null,
            dealerCards: [],
            dealerGame: null,
            additionalCards: [],

            result: null,

            isOpen: false,
            isBet: false,

            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME
            },

            stage: null,
            activeAction: null,
            idOpen: false,
            isBet: false,
            isPass: false,
            isFifth: false,

            used: false,
            exchangeCards: [],

            isPurchase: false,
            purchaseRemovedCard: null,

            showInsurance: false,
            insurance: 0,
            insuranceStatus: null,

            gameInfo: null,
            transactions: [],
            operations: [],
        })
    }


    /* Reconnection Clear */
    reconnectionClear = () => {
        this.setState({
            ante: 0,
            total: 0,
            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME
            },
            isPaused: false,

            gameInfo: null,

            playerGame: null,
            dealerGame: null,

            playerCards: [],
            dealerCards: [],
            additionalCards: [],
            exchangeCards: [],
            purchaseRemovedCard: null,

            isBet: false,
            isPass: false,
            isFifth: false,
            isExchange: false,
            used: false,

            activeAction: null,

            insurance: 0,
            insuranceStatus: null,

            isPurchase: false,
            result: null,

            transactions: [],
            operations: [],
        })
    }


    /* Force End Action */
    forceEnd = () => {

        this.notify("pause", 'close')

        const timer = setTimeout(() => {
            this.notify('forceEnd', 'show')

            const close = setTimeout(() => {
                this.notify('forceEnd', 'close')
                clearTimeout(close)
            }, 4000)

            clearTimeout(timer)
        }, 2000)

        this.setState({
            status: CHOICE,
            isPaused: false,
            ante: 0,
            playerCards: [],
            playerGame: null,
            dealerCards: [],
            dealerGame: null,
            additionalCards: [],
            result: null,
            isOpen: false,
            isBet: false,
            times: {
                choise: times.CARD_HAND_UP_TIME,
                firstGame: times.FIRST_GAME_STAGE_TIME,
                secondGame: times.SECOND_GAME_STAGE_TIME
            },
            stage: null,
            activeAction: null,
            idOpen: false,
            isBet: false,
            isPass: false,
            isFifth: false,
            used: false,
            exchangeCards: [],
            isPurchase: false,
            purchaseRemovedCard: null,
            showInsurance: false,
            insurance: 0,
            insuranceStatus: null,
            gameInfo: null,
            transactions: [],
            operations: [],
        })
    }



    setAnte = (data) => {
        this.setState({ ante: data, last: data, total: data, lastWin: 0 })
        this.socket.emit("ante", data)
        sound.play('sound')
        this.resetTimer()
    }


    /* Toggle Balance Error */
    toggleBalanceError = () => {

        if (!this._balanceErrorTiming) {
            this.notify('balanceError', 'show')
        }

        if (this._balanceErrorTiming) {
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }

        this._balanceErrorTiming = setTimeout(() => {
            this.notify('balanceError', 'close')
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }, 2000)

    }

    /* Toggle Balance Error */
    toggleMaxBetError = () => {

        if (!this._maxBetErrorTiming) {
            this.notify('maxBetError', 'show')
        }

        if (this._maxBetErrorTiming) {
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }

        this._maxBetErrorTiming = setTimeout(() => {
            this.notify('maxBetError', 'close')
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }, 2000)

    }

    action = (type) => {

        const { exchangeCards, total, ante, used } = this.state

        let success = true

        let tempTotal = total


        if (type === "bet") {
            this.socket.emit("bet")
            sound.play('sound')
            this.setState({ isBet: true })

            if (!used && this._playerCards) {
                this.setState({ isExchange: false, exchangeCards: [] })
                this._playerCards.clearCards()
            }

            tempTotal += ante * 2
        }
        if (type === "pass") {
            this.socket.emit("pass")

            if (!used && this._playerCards) {
                this.setState({ isExchange: false, exchangeCards: [] })
                this._playerCards.clearCards()
            }

            this.setState({ isPass: true })
        }
        if (type === "fifth") {
            this.socket.emit("fifth")
            sound.play('selectchip')
            this.openChip()
            tempTotal += ante
            this.setState({ isFifth: true })
        }
        if (type === "swap") {
            this.socket.emit("exchange", exchangeCards)
            sound.play('selectchip')
            this.openChip()
            tempTotal += ante
        }

        if (success) {
            this.setState({ used: true, activeAction: type, status: DEALING, total: tempTotal })
            if (type === "swap" || type === "fifth") {
                this.setState({ playerGame: null })
            }
            this.resetTimer()
        }
    }

    /* Set exchange cards */
    setExchangeCards = cards => {
        this.setState({ exchangeCards: cards })
        sound.play('clickuibut', 0.2)
        this.resetTimer()
    }

    /* Bottom Bet action */
    bottomBet = () => {

        const { status, result, isPass } = this.state

        if (status === GAME && !result && !isPass) {
            this.action('bet')
        }

        this.resetTimer()
    }

    /* Open Chip Animation */
    openChip = () => {
        this.setState({ isOpen: true })
        setTimeout(() => { this.setState({ isOpen: false }) }, 3000)
    }

    /* Show Insurance Box */
    showBoxInsurance = (action) => {
        this.setState({ showInsurance: action === 'show' })
    }

    /* Purchase */
    makePurchase = (value) => {

        const { ante, total } = this.state

        this.setState({ isPurchase: false })

        if (value === 'yes') {
            this.setState({ total: total + ante })
        }

        this.socket.emit("purchase", value)

        this.setState({ isOpen: true })

        sound.play('selectchip') // PLAY SOUND

        setTimeout(() => {
            this.setState({ isOpen: false })
        }, 3000)

        this.resetTimer()
    }

    /* Make Insurance */
    makeInsurance = (value) => {

        const { total, balance, ante } = this.state

        if (balance === null || balance === undefined || parseInt(balance) === 0 || ((parseInt(balance) - parseInt(ante * 2)) <= parseInt(value))) {
            this.notify('balanceInsuranceError', 'show')
            setTimeout(() => {
                this.notify('balanceInsuranceError', 'close')
            }, 5000)
            return
        }

        this.setState({ insurance: value, showInsurance: false, total: total + parseInt(value) })
        this.socket.emit("insurance", value)
        sound.play('selectchip')

        this.resetTimer()
    }


    /* Insurance Button */
    _insuranceButton = () => {

        const { playerGame, insurance, isBet, isPass } = this.state

        let status = false

        if (playerGame && parseInt(playerGame.level) >= 5) {
            status = true
        }

        if (status && parseInt(insurance) === 0 && !isBet && !isPass) {
            return <Insurance onClick={() => this.showBoxInsurance('show')} />
        }
    }


    /* Draw Buttons */
    _buttons = () => {

        const { status, used, isBet, isPass, isPurchase, exchangeCards, activeAction, insurance, showInsurance, result } = this.state

        const isFreezed = (isBet || isPass) && !isPurchase
        const style = (showInsurance || isPurchase || result) ? 'hidden' : ''
        const visible = isFreezed || (status === GAME && !activeAction)

        if (visible) {
            return (
                <div className={`buttons ${style}`}>
                    <div className="buttons-row">
                        {this._insuranceButton()}
                        {!used && exchangeCards.length > 0 ? <Swap onClick={() => this.action("swap")} /> : null}
                        {!used && exchangeCards.length === 0 ? <Fifth onClick={() => this.action("fifth")} /> : null}
                        {!isPass ? <Play selected={isBet} onClick={() => this.action("bet")} /> : null}
                        {!isBet && !insurance ? <Pass selected={isPass} onClick={() => this.action("pass")} /> : null}
                    </div>
                </div>
            )
        }
    }

    /* Draw Result */
    _result = () => {

        const { result, currency } = this.state

        if (result) {
            return <Status status="result" data={result} currency={currency} isPurchase={false} />
        }
    }


    /* Draw Insurance */
    _insurance = () => {

        const { showInsurance, result, ante, status, currency, playerGame, balance, maxPay, chips } = this.state

        if (showInsurance && !result && ante && status === GAME) {
            return (
                <Status
                    status="insurance"
                    currency={currency}
                    playerGame={playerGame}
                    maxPay={maxPay}
                    ante={ante}
                    balance={balance}
                    makeInsurance={insurance => this.makeInsurance(insurance)}
                    close={() => this.showBoxInsurance("close")}
                    chips={chips}
                />
            )
        }
    }


    /* Draw purchase */
    _purchase = () => {

        const { result, isPurchase, showInsurance, ante, isBet, currency, status } = this.state

        if (!result && isPurchase && !showInsurance && ante && isBet) {
            return (
                <Status
                    currency={currency}
                    result={result}
                    status={status}
                    isPurchase={isPurchase}
                    makePurchase={value => this.makePurchase(value)}
                />
            )
        }
    }

    box = () => {

        /* Fields */
        const { status, currency, balance, ante, last, activeChip, dealerCards, dealerGame, playerCards, playerGame, additionalCards, purchaseRemovedCard, exchangeCards, used, result, isBet, isPass, isFifth, isOpen, insurance, insuranceStatus, times, stage, max, min, chips } = this.state
        const gameplay = status === GAME || status === DEALING

        const boxStyle = isPass ? "isPass" : ""

        const lose = result && result.result === "lose"

        /* Shadow active animations for BET and ANTE */
        const anteAnimatedStyle = (stage === "choise" && !ante) && !lose ? "animated" : ""
        const betAnimatedStyle = ((stage === "firstGame" && !used) || (stage === "secondGame" && !isPass && !isBet)) && !lose ? "animated" : ""

        /* GAME */
        if (gameplay) {
            return (
                <div className={`play one-box ${boxStyle}`}>

                    {this._result()}

                    <div className="card-box">

                        <DealerCards
                            cards={dealerCards}
                            game={dealerGame}
                            removedCard={purchaseRemovedCard}
                        />

                        <div className="card-box-bottom">

                            <MobileRate currency={currency} gameScreen={true} ante={ante} result={result} isBet={isBet} isPass={isPass} lose={lose} isOpen={isOpen} insurance={insurance} insuranceStatus={insuranceStatus} add={() => { }} bet={() => this.bottomBet()} boxStyle={boxStyle} chips={chips} />

                            <div className="player-game">

                                <PlayerCards
                                    ref={ref => this._playerCards = ref}
                                    cards={playerCards}
                                    game={playerGame}
                                    additional={additionalCards}
                                    isSecondStage={stage === "secondGame" || stage === "purchaseTime"}
                                    isFifth={isFifth}
                                    isExchange={used && exchangeCards.length > 0}
                                    used={used}
                                    exchangeCards={exchangeCards}
                                    setExchangeCards={cards => this.setExchangeCards(cards)}
                                />

                                <div className="rmp--buttons">
                                    {this._buttons()}
                                </div>

                                {this._insurance()}
                                {this._purchase()}
                            </div>
                        </div>
                    </div>

                    <Rates
                        currency={currency}
                        gameScreen={true}
                        ante={ante}
                        result={result}
                        isBet={isBet}
                        isPass={isPass}
                        isOpen={isOpen}
                        insurance={insurance}
                        insuranceStatus={insuranceStatus}
                        add={() => { }}
                        bet={() => this.bottomBet()}
                        boxStyle={boxStyle}
                        betAnimatedStyle={betAnimatedStyle}
                        lose={lose}
                        chips={chips}
                    />
                </div>
            )
        }

        if (status === CHOICE) {
            return (
                <Bet
                    ante={ante}
                    last={last}
                    active={activeChip}
                    setActiveChip={data => this.setState({ activeChip: data })}
                    setAnte={data => this.setAnte(data)}
                    balance={balance}
                    currency={currency}
                    toggleBalanceError={() => this.toggleBalanceError()}
                    toggleMaxBetError={() => this.toggleMaxBetError()}
                    times={times}
                    boxStyle={boxStyle}
                    anteAnimatedStyle={anteAnimatedStyle}
                    max={max}
                    min={min}
                    chips={chips}
                />
            )
        }

        return (
            <div className={`play one-box ${'isPass'}`}>
                <Rates disabled currency={currency} ante={ante} result={result} isBet={isBet} isOpen={isOpen} insurance={insurance} insuranceStatus={insuranceStatus} add={() => { }} bet={() => this.bottomBet()} boxStyle={"isPass"} chips={chips} />
            </div>
        )

    }

    render = () => {

        const { status, cached, dealerInfo, currency, ante, total, balance, lastWin, used, transactions, gameInfo, operations, histories, historyPage, historyHasMore, closed, newDevice, max, min, maxPay, isDisconnected, stage, isPaused, identifier, isBet, isPass, isPurchase, hls, backColor, gameImg } = this.state

        const isFreezed = (isBet || isPass) && !isPurchase

        const nextGame = status === WAITING

        const style = (nextGame || status === ERROR || status === LOADING || isFreezed || newDevice || closed || isDisconnected || (stage === "firstGame" && used)) ? "hidden" : ""

        const gamePayout = <GamePayout maxPay={maxPay} currency={currency} />

        let content = (
            <Background gameType="rmp" ref={this._background} url={hls} currency={currency} maxPay={maxPay} status={status} dealer={dealerInfo} sendMessage={message => this.sendMessage(message)} info={gameInfo} transactions={transactions} histories={histories} loadMore={() => this.loadHistory(historyPage + 1)} historyHasMore={historyHasMore} identifier={identifier} rules={list} gamePayout={gamePayout} videoBackImg={gameImg} textileColor={backColor}>

                <Timer key="timing" ref={ref => this._timing = ref} style={style} isPaused={isPaused} ante={ante} />
                <InactionTiming ref={ref => this._inaction = ref} status={status} isPaused={isPaused} setInactionState={() => this.setInactionState()} />
                <HookWrapper />
                <GameInfo currency={currency} info={gameInfo} operations={operations} />
                <GameControl title={`European Poker`} maxPay={maxPay} max={max} min={min} currency={currency} info={gameInfo} data={dealerInfo} gamePayout={gamePayout} onQuitGame={() => this.quitGame()} />
                <Balance balance={balance} total={total} lastWin={lastWin} currency={currency} />
                <FloatingMessages ref={ref => this._floatingMessages = ref} />

                {this.box()}

                <Notification nextGame={nextGame} ref={ref => this._notify = ref} currency={currency} max={max} />

                <Internet url={imgUrl} setInternetSpeed={data => this.checkInternetSpeed(data)} />

            </Background>
        )

        if (status === ERROR || newDevice || closed || isDisconnected) {
            content = <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} />
        }

        if (status === LOADING || !cached) {
            content = <Preloader url={gameImg}/>
        }

        return content
    }

}

export default App

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}